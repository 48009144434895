<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-1">
          <div class="alert-info alert p-1 mb-1">
            {{
              $t(
                "BaseModelFields.SwitchFullScreenMode",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <form class="row g-3 form-company-script" novalidate>
            <div class="accordion" id="accordionPanelHtmlCssScript">
              <div class="accordion-item">
                <h2 class="accordion-header" id="html">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#html-body"
                    aria-expanded="true"
                    aria-controls="html"
                  >
                    {{
                      $t(
                        "CompanyInformation.HTML",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </button>
                </h2>
                <div
                  id="html-body"
                  class="accordion-collapse collapse show"
                  aria-labelledby="html"
                >
                  <div class="accordion-body">
                    <v-ace-editor
                      v-model:value="model.globalHtml"
                      @init="editorInit"
                      lang="html"
                      theme="monokai"
                      style="height: 250px"
                      id="htmlEditor"
                      :options="options"
                    />
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="css">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#css-body"
                    aria-expanded="true"
                    aria-controls="css-body"
                  >
                    {{
                      $t(
                        "CompanyInformation.CSS",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </button>
                </h2>
                <div
                  id="css-body"
                  class="accordion-collapse collapse show"
                  aria-labelledby="css"
                >
                  <div class="accordion-body">
                    <v-ace-editor
                      v-model:value="model.globalCss"
                      lang="css"
                      theme="monokai"
                      @init="editorInit"
                      :options="options"
                      id="cssEditor"
                      style="height: 250px"
                    />
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="script">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#script-body"
                    aria-expanded="true"
                    aria-controls="script-body"
                  >
                    {{
                      $t(
                        "CompanyInformation.Script",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </button>
                </h2>
                <div
                  id="script-body"
                  class="accordion-collapse collapse show"
                  aria-labelledby="script"
                >
                  <div class="accordion-body">
                    <v-ace-editor
                      v-model:value="model.globalScript"
                      lang="javascript"
                      theme="monokai"
                      @init="editorInit"
                      :options="options"
                      id="scriptEditor"
                      style="height: 250px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/theme-monokai";

import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-css";

import beautifier from "ace-builds/src-noconflict/ext-beautify";
import "ace-builds/src-noconflict/ext-language_tools";

import "ace-builds/src-noconflict/snippets/html";
import "ace-builds/src-noconflict/snippets/javascript";
import "ace-builds/src-noconflict/snippets/css";

import "ace-builds/src-noconflict/ext-searchbox";

import ace from "ace-builds";
import workerHtmlUrl from "file-loader?esModule=false!ace-builds/src-noconflict/worker-html.js";
ace.config.setModuleUrl("ace/mode/html_worker", workerHtmlUrl);

import workerJsUrl from "file-loader?esModule=false!ace-builds/src-noconflict/worker-javascript.js";
ace.config.setModuleUrl("ace/mode/javascript_worker", workerJsUrl);

import workerCssUrl from "file-loader?esModule=false!ace-builds/src-noconflict/worker-css.js";
ace.config.setModuleUrl("ace/mode/css_worker", workerCssUrl);

export default {
  name: "GlobalScriptsEdit",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    VAceEditor,
  },
  data() {
    return {
      model: {
        globalHtml: "",
        globalCss: "",
        globalScript: "",
        htmlEditor: null,
        scriptEditor: null,
        cssEditor: null,
      },
      errors: [],
      companyData: this.$parent.companyData,
      options: {
        autoScrollEditorIntoView: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        useWorker: true,
        wrap: true,
      },
      editorInit: function (editor) {
        editor.setOptions({
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true,
          autoScrollEditorIntoView: true,
        });
        editor.setShowPrintMargin(false);
      },
    };
  },
  methods: {},
  mounted() {
    this.model = this.data;

    this.model.htmlEditor = ace.edit("htmlEditor");
    this.model.htmlEditor.commands.addCommand(
      this.$root.ace.commands.fullScreen
    );

    this.model.scriptEditor = ace.edit("scriptEditor");
    this.model.scriptEditor.commands.addCommand(
      this.$root.ace.commands.fullScreen
    );

    this.model.cssEditor = ace.edit("cssEditor");
    this.model.cssEditor.commands.addCommand(
      this.$root.ace.commands.fullScreen
    );

    beautifier.beautify(this.model.scriptEditor.session);
    beautifier.beautify(this.model.htmlEditor.session);
    beautifier.beautify(this.model.cssEditor.session);
  },
};
</script>
