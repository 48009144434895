<template>
  <form class="g-3 form-company-profile" novalidate>
    <div class="row mt-2 mb-2">
      <div class="col-md-6 mb-2">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "CompanyInformation.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="companyData.name"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col-md-6 mb-2">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "CompanyInformation.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control text-uppercase"
          id="FormulaName"
          :value="companyData.formulaName"
          :spellcheck="this.$isTextSpellCheck"
          disabled
          required
        />
      </div>
      <div class="col-md-6 mb-2">
        <label for="AppName" class="form-label required"
          >{{
            $t(
              "CompanyInformation.CompanyName",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="AppName"
          v-model="companyData.appName"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col-md-6 mb-2">
        <label for="TaxOffice" class="form-label"
          >{{
            $t(
              "CompanyInformation.TaxOffice",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="TaxOffice"
          v-model="companyData.taxOffice"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col-md-6 mb-2">
        <label for="TaxNo" class="form-label"
          >{{
            $t(
              "CompanyInformation.TaxNo",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="TaxNo"
          v-model="companyData.taxNo"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col-md-6 mb-2">
        <label for="Country" class="form-label"
          >{{
            $t(
              "CompanyInformation.Country",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Country"
          v-model="companyData.country"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col-md-6 mb-2">
        <label for="City" class="form-label"
          >{{
            $t(
              "CompanyInformation.City",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="City"
          v-model="companyData.city"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col-md-6 mb-2">
        <label for="County" class="form-label"
          >{{
            $t(
              "CompanyInformation.County",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="County"
          v-model="companyData.county"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col-md-6 mb-2">
        <label for="Address" class="form-label"
          >{{
            $t(
              "CompanyInformation.Address",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Address"
          v-model="companyData.address"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col-md-6 mb-2">
        <label for="Phone" class="form-label"
          >{{
            $t(
              "CompanyInformation.Phone",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Phone"
          v-model="companyData.phone"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col-md-6 mb-2">
        <label for="Fax" class="form-label"
          >{{
            $t(
              "CompanyInformation.Fax",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Fax"
          v-model="companyData.fax"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col-md-6 mb-2">
        <label for="ContactPerson" class="form-label"
          >{{
            $t(
              "CompanyInformation.ContactPerson",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="ContactPerson"
          v-model="companyData.contactPerson"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col-md-6 mb-2">
        <label for="ContactPersonEmail" class="form-label"
          >{{
            $t(
              "CompanyInformation.ContactPersonEmail",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="email"
          class="form-control"
          id="ContactPersonEmail"
          v-model="companyData.contactPersonEmail"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col-md-6 mb-2">
        <label for="ContactPersonTel" class="form-label"
          >{{
            $t(
              "CompanyInformation.ContactPersonTel",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="ContactPersonTel"
          v-model="companyData.contactPersonTel"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
        />
      </div>
      <div class="col-12 mt-3">
        <div class="form-check">
          <label for="IsIpControl" class="form-label">{{
            $t(
              "CompanyInformation.IsIpControl",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsIpControl"
            v-model="companyData.isIpControl"
          />
        </div>
      </div>
      <div class="row row-cols-1 mt-3">
        <div class="col col-md-12">
          <label for="AcceptIpAddresses" class="form-label"
            >{{
              $t(
                "CompanyInformation.AcceptIpAddresses",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </label>
          <textarea
            name="AcceptIpAddresses"
            class="form-control"
            id="AcceptIpAddresses"
            v-model="companyData.acceptIpAddresses"
            :spellcheck="this.$isTextSpellCheck"
            rows="2"
          ></textarea>
        </div>
      </div>
      <div class="row row-cols-1 mt-3">
        <div class="col col-md-12">
          <label for="GoogleMapsApiKey" class="form-label"
            >{{
              $t(
                "CompanyInformation.GoogleMapsApiKey",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </label>
          <textarea
            name="GoogleMapsApiKey"
            class="form-control"
            id="GoogleMapsApiKey"
            v-model="companyData.googleApiKey"
            :spellcheck="this.$isTextSpellCheck"
            rows="2"
          ></textarea>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
export default {
  name: "CompanyInformationEdit",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      companyData: {},
    };
  },
  methods: {},
  mounted() {
    this.companyData = this.data;
    this.$root.preparePhoneNumber(
      "ContactPersonTel",
      this.companyData.contactPersonTel
    );
    this.$root.preparePhoneNumber("Phone", this.companyData.phone);
    this.$root.preparePhoneNumber("Fax", this.companyData.fax);
  },
};
</script>
