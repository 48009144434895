<template>
  <SetLoader v-if="!isControlCompanyData" classes="mt-5" />
  <template v-else>
    <Breadcrumb v-if="detailTitle" :detailTitle="detailTitle.toUpperCase()" />
    <Breadcrumb v-else detailTitle="&nbsp;" />
    <div class="row">
      <div class="col">
        <div class="card customobject-detail-container">
          <div class="row">
            <div class="col-xl-3 col-xxl-3">
              <div class="customobject-detail-menu">
                <ul
                  class="nav nav-tabs mb-2 list-unstyled customobject-detail-status-filter"
                  role="tablist"
                >
                  <li class="nav-item w-100" role="presentation">
                    <a
                      class="co-left-nav-link"
                      :class="{
                        active: selectedTabValue == 'generalDetails',
                      }"
                      @click="selectedTab('generalDetails')"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-system-tab"
                      type="button"
                      role="tab"
                      aria-selected="true"
                    >
                      <i class="bi bi-info-circle"></i
                      >{{
                        $t(
                          "CompanyInformation.GeneralDetails",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </a>
                  </li>
                  <li class="nav-item w-100" role="presentation">
                    <a
                      class="co-left-nav-link"
                      :class="{
                        active: selectedTabValue == 'globalScripts',
                      }"
                      @click="selectedTab('globalScripts')"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-company-tab"
                      type="button"
                      role="tab"
                      aria-selected="false"
                    >
                      <i class="bi bi-info-circle"></i
                      >{{
                        $t(
                          "CompanyInformation.GlobalScripts",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </a>
                  </li>
                </ul>
                <button
                  type="button"
                  class="btn btn-success btn-save d-block m-b-lg w-100"
                  @click="onSubmit('btn-save')"
                >
                  <span
                    ><i class="bi bi-pencil-square"></i>
                    {{
                      $t(
                        "Buttons.Save",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</span
                  >
                </button>
              </div>
            </div>
            <div class="col-xl-9 col-xxl-9">
              <div v-if="errors.length > 0" class="alert alert-warning">
                <ul class="mb-0">
                  <li v-for="error in errors" v-bind:key="error">
                    {{ error }}
                  </li>
                </ul>
              </div>
              <div class="customobject-detail">
                <div
                  v-show="selectedTabValue == 'generalDetails'"
                  class="tab-content"
                >
                  <EditCompanyInformation
                    ref="editCompanyInformation"
                    :data="companyData"
                  ></EditCompanyInformation>
                </div>
                <div
                  v-show="selectedTabValue == 'globalScripts'"
                  class="tab-content"
                >
                  <EditGlobalScript
                    ref="editGlobalScript"
                    :data="companyScriptData"
                  ></EditGlobalScript>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import EditCompanyInformation from "@/views/CompanyInformation/Edit";
import EditGlobalScript from "@/views/CompanyInformation/EditGlobalScript";
import { createToast } from "mosha-vue-toastify";
import $ from "jquery";
export default {
  name: "LayoutCompanyInformation",
  components: {
    EditCompanyInformation,
    EditGlobalScript,
  },
  data() {
    return {
      detailTitle: "",
      selectedTabValue: "generalDetails",
      companyData: {},
      companyScriptData: {
        globalHtml: "",
        globalScript: "",
        globalCss: "",
      },
      isControlCompanyData: false,
      errors: [],
      disabledButtonClasses: ".btn-save",
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
    };
  },
  methods: {
    selectedTab(tab) {
      this.selectedTabValue = tab;
    },
    onSubmit(buttonName) {
      var form = $(".form-company-profile");
      form.addClass("was-validated");
      if (!form[0].checkValidity()) {
        this.selectedTabValue = "generalDetails";
        return;
      }

      this.companyData = this.$refs.editCompanyInformation.companyData;
      var globalScriptModel = this.$refs.editGlobalScript.model;
      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.companyData.globalHtml = globalScriptModel.globalHtml.toString();
      this.companyData.globalScript = globalScriptModel.globalScript.toString();
      this.companyData.globalCss = globalScriptModel.globalCss.toString();
      this.companyData.isCompanyCacheNeeded = false;
      this.companyData.isCompanyCacheNeeded =
        !String.isNullOrWhiteSpace(this.companyData.html) ||
        !String.isNullOrWhiteSpace(this.companyData.script) ||
        !String.isNullOrWhiteSpace(this.companyData.css);

      this.$prodGatewayAxios
        .post("/Lcdp-ArgesetEdit", { ...this.companyData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.detailTitle = response.name;
            createToast(
              this.$t(
                "Messages.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: "true",
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    getProjectDetail() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-ArgesetGetCompanyProfile?id={0}",
            this.$store.state.companyPublicId
          )
        )
        .then((response) => {
          var result = response.data;
          this.companyData = result;
          this.detailTitle = result.name;
          this.isControlCompanyData = result.isOk;

          if (!String.isNullOrWhiteSpace(response.data.globalHtml)) {
            this.companyScriptData.globalHtml = response.data.globalHtml;
          }

          if (!String.isNullOrWhiteSpace(response.data.globalScript)) {
            this.companyScriptData.globalScript = response.data.globalScript;
          }

          if (!String.isNullOrWhiteSpace(response.data.globalCss)) {
            this.companyScriptData.globalCss = response.data.globalCss;
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getProjectDetail();
  },
};
</script>
